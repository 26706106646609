<div [ngClass]="{'no-scroll': general_loader}">

  <nav class="navbar fixed-top navbar-dark navbar-expand-md" style="background-color: #133285; width: 100%">
    <img src="../../../assets/uniservlogo.svg" style="width: 200px" alt="SERV®" />
  </nav>

  <div style="margin-top: 128px;width: 100%;">
    <img src="../../../assets/bannerform.gif" style="width: 100%;" alt="SERV®" />
  </div>

  <h1
    style="background-color: #ffffff; font-family: Arial, Helvetica, sans-serif;padding: 5px;font-size: medium;width: 100%;color: rgb(0, 0, 0);text-align: center;margin-top: 30px;">
    Estás muy cerca de unirte al futúro de los servicios, capacitate gratis en UniSERV&#xAE; y monetiza tus conocimientos
    en SERV&#xAE; App <br> Completa el siguiente formulario con tus datos para participar en el proceso de selección
  </h1>

  <div *ngIf="general_loader" style="position: fixed;top: 0;left: 0; width: 100%; height: 100%;z-index: 101;">
    <div class="loader-container">
      <!-- <img src="../../../assets/img/gifserv1.gif" /> -->
      <div class="loader"></div>
      <div class="loader2"></div>
    </div>
  </div>

  <div class="form-container" style="max-width: 1200px">

    <form [formGroup]="contactForm">
      <div id="test-l-1" class="content">

        <div class="form-group">

          <!--input de la especialidad-->
          <!--
              <div class="row"> 
                <div class="col-md-6">
          <select formControlName="especialidad">
              <option [ngValue]="null" disabled>Selecciona tu perfil</option>
              <option *ngFor="let esp of especialidades" [ngValue]="esp.value">
                  {{esp.viewValue}}
              </option>
          </select>
          </div>
          -->

          <!--
              <div class="col-md-6">
          <select formControlName="comoTeEnteraste">
              <option [ngValue]="null" disabled>Como te enteraste de SERV</option>
              <option *ngFor="let esp of fuentes" [ngValue]="esp.value">
                  {{esp.viewValue}}
              </option>
          </select>
          </div>
          </div>
          -->
          <!--
              <div class="row"> 
              <div class="col-md-6">
                  <select formControlName="experienciaEnAseo1">
                      <option [ngValue]="null" disabled>¿Cuentas con experiencia en servicios generales o aseo?</option>
                  <option value="tiene experiencia en aseo">Si</option>
                  <option value="no cuenta con experiencia en aseo">No</option>
              </select>
              </div>
              </div>
          -->

          <!--input del nombre-->
          <div class="row">
            <div class="col-lg-12">
              <label class="titulos"> ¿A que vacante estas aplicando? </label>
              <select class="required" formControlName="especialidad" [(ngModel)]="visualizarHabilidad">
                <option value="yes" disabled>Selecciona...</option>
                <option *ngFor="let esp of especialidades" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <label class="titulos">Ingresa tu nombre</label>
              <input type="text" formControlName="nombre" placeholder="Escribe tu nombre" />
              <div class="alert alert-danger" style="margin-top: -10px"
                *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)">
                <div *ngIf="nombre.errors.required">El nombre es requerido</div>
                <div *ngIf="nombre.errors.minlength">
                  El nombre debe ser de al menos 3 caracteres de largo
                </div>
                <div *ngIf="nombre.errors.pattern">El nombre debe ser valido</div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="titulos">Ingresa tu apellido</label>
              <input type="text" formControlName="apellido" placeholder="Escribe tu apellido" />
              <div class="alert alert-danger" style="margin-top: -10px"
                *ngIf="apellido.invalid && (apellido.dirty || apellido.touched)">
                <div *ngIf="apellido.errors.required">
                  El apellido es requerido
                </div>
                <div *ngIf="apellido.errors.minlength">
                  El apellido debe ser de al menos 3 caracteres de largo
                </div>
                <div *ngIf="apellido.errors.pattern">
                  El apellido debe ser valido
                </div>
              </div>
            </div>
            <!--input del email-->
            <!--
                    <div class="col-lg-4"> 
                
              <input type="text" formControlName="email" placeholder="Escribe tu email">
              <div class="alert alert-danger" style="margin-top: -10px;" *ngIf="email.invalid && (email.dirty || email.touched)">
                  <div class="container-error-message" *ngIf="email.errors.required">
                  El email es requerido
                  </div>
                  <div class="container-error-message" *ngIf="email.errors.minlength">
                  El email debe ser de al menos 5 caracteres de largo
                  </div>
                  <div class="container-error-message" *ngIf="(email.dirty || email.touched) && email.invalid && email.errors.pattern">
                  El email tiene que ser valido
                  </div>
              </div>
          </div>
              -->
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="titulos">Selecciona tu tipo de documento</label>
              <select formControlName="tipodocumento" placeholder="Selecciona tu tipo de documento">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of documentos" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
            <div class="col-md-6">
              <label class="titulos">Ingresa tu número de documento</label>
              <input type="text" formControlName="documento" placeholder="Mínimo 3 caracteres" />
              <div class="alert alert-danger" style="margin-top: -10px" *ngIf="
                  documento.invalid && (documento.dirty || documento.touched)
                ">
                <div class="container-error-message" *ngIf="documento.errors.required">
                  La cedula es requerida
                </div>
                <div class="container-error-message" *ngIf="documento.errors.minlength">
                  La cedula debe ser de al menos 3 caracteres de largo
                </div>
                <div class="container-error-message" *ngIf="documento.errors.pattern">
                  La cedula debe ser valida
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="titulos">Selecciona tu país de origen</label>
              <select [(ngModel)]="visualizarDocumento" formControlName="nacionalidad">
                <option value="yes" disabled>Selecciona...</option>
                <option *ngFor="let esp of pais" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>

            <div *ngIf="
                visualizarDocumento !== 'colombia' &&
                visualizarDocumento !== 'yes'
              " class="col-md-6">
              <label class="titulos">¿Tienes permiso para trabajar vigente?</label>
              <select formControlName="permiso">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div class="col-lg-6">
              <label class="titulos">Ingresa tu número de telefono</label>
              <input type="text" formControlName="telefono" placeholder="Mínimo 7 caracteres" />
              <div class="alert alert-danger" style="margin-top: -10px"
                *ngIf="telefono.invalid && (telefono.dirty || telefono.touched)">
                <div class="container-error-message" *ngIf="telefono.errors.required">
                  El telefono es requerido
                </div>
                <div class="container-error-message" *ngIf="telefono.errors.minlength">
                  El telefono debe ser de al menos 7 caracteres de largo
                </div>
                <div class="container-error-message" *ngIf="telefono.errors.maxlength">
                  El telefono debe ser máximo de 10 caracteres de largo
                </div>
                <div class="container-error-message" *ngIf="telefono.errors.pattern">
                  El telefono debe ser valido
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6">
              <label class="titulos">Ingresa tu fecha de nacimiento</label>
              <input placeholder="Formato día/mes/año" class="textbox-n" formControlName="fechaDeNacimiento" [max]="fechaNacimientoMaxima"
                type="date" />
            </div>

            <div class="col-lg-6">
              <label class="titulos">Ingresa tu dirección de residencia</label>
              <input type="text" formControlName="direccion" placeholder="Mínimo 3 caracteres" />
              <div class="alert alert-danger" *ngIf="
                  direccion.invalid && (direccion.dirty || direccion.touched)
                ">
                <div class="container-error-message" *ngIf="direccion.errors.required">
                  La dirección es requerida
                </div>
                <div class="container-error-message" *ngIf="direccion.errors.minlength">
                  La dirección debe ser de al menos 3 caracteres de largo
                </div>
                <div class="container-error-message" *ngIf="direccion.errors.pattern">
                  La dirección debe ser valida
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <label class="titulos">Selecciona tu ciudad o municipío de residencia</label>
              <select formControlName="ciudad">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of ciudades" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
            <div class="col-lg-6">
              <label class="titulos">Selecciona tu zona de residencia</label>
              <select formControlName="zona">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of zonas" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <label class="titulos">Ingresa tu barrio de residencia</label>
              <input type="text" formControlName="barrio" placeholder="Mínimo 3 caracteres" />
              <div class="alert alert-danger" *ngIf="barrio.invalid && (barrio.dirty || barrio.touched)">
                <div class="container-error-message" *ngIf="barrio.errors.required">
                  el barrio es requerido
                </div>
                <div class="container-error-message" *ngIf="barrio.errors.minlength">
                  el barrio debe ser de al menos 3 caracteres de largo
                </div>
                <div class="container-error-message" *ngIf="barrio.errors.pattern">
                  el barrio debe ser valido
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <label class="titulos">Ingresa tu dirección de correo electrónico</label>
              <input type="text" formControlName="email" placeholder="Mínimo 5 caracteres" />
              <div class="alert alert-danger" style="margin-top: -10px"
                *ngIf="email.invalid && (email.dirty || email.touched)">
                <div class="container-error-message" *ngIf="email.errors.required">
                  El email es requerido
                </div>
                <div class="container-error-message" *ngIf="email.errors.minlength">
                  El email debe ser de al menos 5 caracteres de largo
                </div>
                <div class="container-error-message" *ngIf="
                    (email.dirty || email.touched) &&
                    email.invalid &&
                    email.errors.pattern
                  ">
                  El email tiene que ser valido
                </div>
              </div>
            </div>
          </div>

          <h1 style="background-color: #ffffff;font-family: Arial, Helvetica, sans-serif;color: black;">
            Preguntas adicionales
          </h1>
          <div class="row">
            <div class="col-md-6">
              <label class="titulos">¿Como te enteraste de SERV?</label>
              <select formControlName="comoTeEnteraste">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of fuentes" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>

            <div class="col-md-6" *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Conoces la aplicación google maps?</label>
              <select formControlName="utilizaGoogleMaps">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of googleMaps" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label class="titulos">¿Nivel educativo?</label>
              <select formControlName="nivelEducativo">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of niveleseducativos" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
            <div class="col-md-6" *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Cuentas con smartphone, con datos y recargas?</label>
              <select formControlName="smartphone">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
            </div>
          
            <div class="col-md-6" *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Cuántos hijos tienes?</label>
              <select formControlName="numeroDeHijos">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of hijos" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
            <div class="col-md-6" *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Cúal es la edad de tus hijos?</label>
              <select formControlName="edadDeHijos">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of edadeshijos" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>


            <!-- <div class="row">
                      <div class="col-md-6">
                <select formControlName="sabePlanchar">
                  <option [ngValue]="null" disabled>
                    ¿Sabes planchar camisas y lavar prendas delicadas?
                  </option>
                  <option *ngFor="let esp of planchar" [ngValue]="esp.value">
                    {{ esp.viewValue }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <select formControlName="sabeCocinar">
                  <option [ngValue]="null" disabled>
                    ¿Tienes habilidades de cocina?
                  </option>
                  <option *ngFor="let esp of habilidades" [ngValue]="esp.value">
                    {{ esp.viewValue }}
                  </option>
                </select>
              </div> 
            </div> -->
          
            <div class="col-md-6"  *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Podrías trabajar a las 6:00 am?</label>
              <select formControlName="ingresaTemprano">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of temprano" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
            <div class="col-md-6"  *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Que modalidad de trabajo esta buscando?</label>
              <select formControlName="disponibilidadDeTiempo">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of disponibilidades" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>
            <div class="col-md-6"  *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Cuentas con moto?</label>
              <select formControlName="tieneMoto">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>
            </div>

            <div class="col-md-6" *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">¿Qué tipo de persona eres?</label>
              <select formControlName="tipoPersona">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option *ngFor="let esp of tipoPersona" [ngValue]="esp.value">
                  {{ esp.viewValue }}
                </option>
              </select>
            </div>

            <div class="col-md-6"  *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' &&  visualizarHabilidad != 'reclutamiento'">
              <label class="titulos">Escoje una de las siguientes opciones</label>
              <select formControlName="dispuestoEnMovilizarce">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option value="no">Prefiero ganar menos dinero pero estar en un lugar de trabajo fijo</option>
                <option value="si">Prefiero ganar mas dinero y estoy dispuesto/a en movilizame a diferetnes lugares</option>
              </select>
            </div>

            <div class="col-md-6">
              <label class="titulos">
                ¿Cuentas con experiencia en {{visualizarHabilidad}}?
              </label>
              <select formControlName="experienciaEnAseo1">
                <option [ngValue]="null" disabled>Selecciona...</option>
                <option value="experiencia">Si</option>
                <option value="sin experiencia">No</option>
              </select>
            </div>
          </div>

          <h1 *ngIf="visualizarHabilidad != 'vendedor' && visualizarHabilidad != 'logistica' && visualizarHabilidad != 'reclutamiento'"  style="background-color: #ffffff; font-family: Arial, Helvetica, sans-serif;color: black;">
            ¿Que habilidades tienes?
          </h1>
          <div *ngIf="visualizarHabilidad === 'empleada domestica'" class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="aseo" class="form-check-input">
                  Aseo general</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="planchado" class="form-check-input">
                  Planchado</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="cocina" class="form-check-input">
                  Cocina</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="ninos" class="form-check-input">
                  Cuidado de niños</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="adultos" class="form-check-input">
                  Cuidado de adultos</mat-checkbox><br />
              </div>
            </div>
          </div>
          <div *ngIf="visualizarHabilidad === 'auxiliar de servicios generales'" class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="residuos" class="form-check-input">
                  Manejo de residuos</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="desinfeccion" class="form-check-input">
                  Desinfección de equipos</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="cafeteria" class="form-check-input">
                  Cafeteria</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="atencion" class="form-check-input">
                  Atención en reuniones</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="vidrios" class="form-check-input">
                  Limpieza de vidrios</mat-checkbox><br />
              </div>
            </div>
          </div>
          <div *ngIf="visualizarHabilidad === 'toderos'" class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="electricista" class="form-check-input">
                  Electricista</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="plomero" class="form-check-input">
                  Plomero</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="pintor" class="form-check-input">
                  Pintor</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="jardinero" class="form-check-input">
                  Jardinero</mat-checkbox><br />
              </div>
            </div>
          </div>
          <div *ngIf="visualizarHabilidad === 'conductor'" class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="ingles" class="form-check-input">
                  Hablo inglés</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="licencia" class="form-check-input">
                  Licencia</mat-checkbox><br />
              </div>

            </div>
          </div>
          <div *ngIf="visualizarHabilidad === 'fotografo'" class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="ingles" class="form-check-input">
                  Hablo inglés</mat-checkbox><br />
              </div>
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="camara" class="form-check-input">
                  Cuento con cámara</mat-checkbox><br />
              </div>

            </div>
          </div>
          <div *ngIf="visualizarHabilidad === 'guia'" class="row">
            <div class="col-md-6">
              <div class="form-group form-check">
                <mat-checkbox style="margin-bottom: 15px" formControlName="ingles" class="form-check-input">
                  Hablo inglés</mat-checkbox><br />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label class="titulos">Cuentanos sobre ti</label>
              <textarea style="padding: 12px;" id="texArea_experiencia"  (keyup)="autoGrowTextZone($event)" formControlName="habilidades" ></textarea>
            </div>
            <div class="col-md-6" *ngIf="!documento.invalid">
              <label class="titulos">Anexa hoja de vida (opcional)</label>
              <label class="text-button button-secondary" for="fileHojaVida" style="width: 100%;">
                <i class="fa fa-upload"></i>
                <span>Subir archivo</span>
              </label>
              <input id="fileHojaVida" type="file" accept=".png, .jpg, .pdf" (change)="onUploadHojaVida($event)" style="display: none;">
            </div>
          </div>
        </div>
      </div>
    
      <button type="submit" class="text-button button-primary" (click)="getPuntaje()" style="width: 100%;">
        Finalizar y enviar
      </button>
      <!-- <button type="submit" class="btn-primary  btn-block shadow p-4 rounded"
        style="border-radius: 5px; color: white; font-size: large" (click)="pruebas()">
        pruebas
      </button> -->
    </form>
  </div>

</div>