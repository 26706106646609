import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import { AngularFireModule } from '@angular/fire';
import {MessageI} from '../models/message.interface';
import { messaging } from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class DataDbService {
  private contactoCollection: AngularFirestoreCollection<MessageI>
  constructor(private afs: AngularFirestore) { 
    this.contactoCollection = afs.collection<MessageI>('aspirantes');
  }

  async saveAspiranteServer(data: any):Promise<void>{
    const result = await this.contactoCollection.add(data);
    console.log(result.id);
    return result.update({id: result.id})
  }
  
  async saveAspiranteServ(data: any):Promise<void>{
    const result = await  this.afs.collection('aspirantesServ').add(data)
    return result.update({id: result.id})
  }
  
  getCorreo(){
    return this.contactoCollection;
  }
}
