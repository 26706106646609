import { Component, OnInit } from '@angular/core';
import { DataDbService } from '../../services/data-db.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import {
  AngularFirestore,
} from '@angular/fire/firestore';
// import Stepper from 'bs-stepper';
import { Observable } from 'rxjs';
import { take, finalize } from 'rxjs/operators';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { Router } from '@angular/router';
declare let fbq:Function;

@Component({
  selector: 'contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
})
export class ContactoComponent implements OnInit {
  general_loader = false;
  public puntaje: number = 0;
  // private stepper: Stepper;
  public tipoAgendamiento: string = 'falta';
  private namePattern: any = '[A-Za-z ]{1,32}';
  private emailPattern: any =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private numberPattern: any = '[0-9]+';
  public visualizarDocumento = 'yes';
  public visualizarHabilidad = '';
  public fechaNacimientoMaxima = moment().subtract(18,'years').format('YYYY-MM-DD')

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL: Observable<string>;
  uploadState: Observable<string>;

  especialidades: any = [
    { value: 'empleada domestica', viewValue: 'Empleada doméstica' },
    { value: 'interna', viewValue: 'Empleada interna' }, 
    {value: 'auxiliar de servicios generales',viewValue: 'Auxiliar de servicios generales' },
    { value: 'cocinero', viewValue: 'Cocinero o ayudante de cocina' },
    { value: 'toderos', viewValue: 'Todero o mantenimiento' },
    { value: 'conductor', viewValue: 'Conductor bilingüe' },
    { value: 'fotografo', viewValue: 'Fotográfo bilingüe' },
    { value: 'guia', viewValue: 'Guía turístico bilingüe' },
    { value: 'vendedor', viewValue: 'Ejecutivo comercial' },
    { value: 'logistica', viewValue: 'Coordinador de personal' },
    { value: 'reclutamiento', viewValue: 'Lider de reclutamiento y seleccion'},];
    
  fuentes: any = [
    { value: 'google', viewValue: 'Google' },
    { value: 'facebook', viewValue: 'Facebook' },
    { value: 'recomendado', viewValue: 'Un conocido me recomendo' },
    { value: 'email', viewValue: 'E-mail' },
    { value: 'otro', viewValue: 'Otro' },
  ];
  documentos: any = [
    { value: 'cedula', viewValue: 'Cédula de Ciudadanía' },
    { value: 'extranjeria', viewValue: 'Cédula de Extranjería' },
    { value: 'pasaporte', viewValue: 'Pasaporte' },
    { value: 'pep', viewValue: 'PEP' },
    { value: 'ppt', viewValue: 'PPT' },
  ];

  pais: any = [
    { value: 'colombia', viewValue: 'Colombia' },
    { value: 'venezuela', viewValue: 'Venezuela' },
    { value: 'argentina', viewValue: 'Argentina' },
    { value: 'bolivia', viewValue: 'Bolivia' },
    { value: 'chile', viewValue: 'Chile' },
    { value: 'ecuador', viewValue: 'Ecuador' },
    { value: 'mexico', viewValue: 'Mexico' },
    { value: 'panama', viewValue: 'Panama' },
    { value: 'peru', viewValue: 'Peru' },
    { value: 'uruguay', viewValue: 'Uruguay' },
  ];

  ciudades: any = [
    { value: 'bello', viewValue: 'Bello' },
    { value: 'bogota', viewValue: 'Bogotá' },
    { value: 'cajica', viewValue: 'Cajicá' },
    { value: 'chia', viewValue: 'Chía' },
    { value: 'copacabana', viewValue: 'Copacabana' },
    { value: 'cota', viewValue: 'Cota' },
    { value: 'envigado', viewValue: 'Envigado' },
    { value: 'el retiro', viewValue: 'El retiro' },
    { value: 'funza', viewValue: 'Funza' },
    { value: 'fusagasuga', viewValue: 'Fusagasugá' },
    { value: 'itagui', viewValue: 'Itagüí' },
    { value: 'la calera', viewValue: 'La calera' },
    { value: 'llano grande', viewValue: 'Llano grande' },
    { value: 'las palmas', viewValue: 'Las palmas' },
    { value: 'madrid', viewValue: 'Madrid' },
    { value: 'medellin', viewValue: 'Medellín' },
    { value: 'mosquera', viewValue: 'Mosquera' },
    { value: 'rionegro', viewValue: 'Rionegro' },
    { value: 'sabaneta', viewValue: 'Sabaneta' },
    { value: 'siberia', viewValue: 'Siberia' },
    { value: 'soacha', viewValue: 'Soacha' },
    { value: 'zipaquira', viewValue: 'Zipaquirá' },

  ];
  zonas: any = [
    { value: 'norte', viewValue: 'Zona Norte' },
    { value: 'sur', viewValue: 'Zona Sur' },
    { value: 'occidente', viewValue: 'Zona Occidente' },
    { value: 'oriente', viewValue: 'Zona Oriente' },
    { value: 'centro', viewValue: 'Zona Centro' },
  ];
  estadosciviles: any = [
    { value: 'soltero', viewValue: 'Soltero' },
    { value: 'casado', viewValue: 'Casado' },
    { value: 'union libre', viewValue: 'Union libre' },
    { value: 'madre soltera', viewValue: 'Madre soltera' },
    { value: 'padre soltero', viewValue: 'Padre Soltero' },
  ];
  hijos: any = [
    { value: '0', viewValue: '0' },
    { value: '1', viewValue: '1' },
    { value: '2', viewValue: '2' },
    { value: '3', viewValue: '3' },
    { value: '4', viewValue: '4' },
    { value: '5', viewValue: '5' },
  ];
  edadeshijos: any = [
    { value: 'no tengo hijos', viewValue: 'No tengo hijos' },
    { value: 'menos de 3 años', viewValue: 'Menos de 3 años' },
    { value: 'mas de 3 años', viewValue: 'Más de 3 años' },
  ];

  tipoPersona: any = [
    {
      value: 'soy una persona tranquila y callada',
      viewValue: 'Soy una persona tranquila y callada',
    },
    {
      value: 'soy una persona alegre y habladora',
      viewValue: 'Soy una persona alegre y habladora',
    },
  ];

  niveleseducativos: any = [
    { value: 'primaria', viewValue: 'Primaria' },
    { value: 'bachillerato', viewValue: 'Bachillerato' },
    { value: 'tecnico o tecnologo', viewValue: 'Técnico o Tecnológo' },
    { value: 'profesional', viewValue: 'Profesional' },
  ];

  estudias: any = [
    { value: 'estudio de noche', viewValue: 'Si, en las noches' },
    { value: 'estudio los sabados', viewValue: 'Si, los sabados' },
    { value: 'estudio virtual', viewValue: 'Si, virtual' },
    { value: 'estudio entre semana', viewValue: 'Si, días entre semana' },
    { value: 'no estudio', viewValue: 'No' },
  ];

  disponibilidades: any = [
    { value: 'algunos dias', viewValue: 'Algunos días' },
    { value: 'medio tiempo', viewValue: 'Medio tiempo' },
    { value: 'tiempo completo', viewValue: 'Tiempo completo' },
    { value: 'fines de semana', viewValue: 'Sólo fines de semana' },
  ];

  planchar: any = [
    { value: 'si plancho', viewValue: 'Si, las plancho perfecto y me gusta' },
    { value: 'quiere aprender', viewValue: 'No, pero quiero aprender' },
    { value: 'no plancho', viewValue: 'No, nunca he planchado y no me gusta' },
  ];
  flexibles: any = [
    { value: 'si', viewValue: 'Si' },
    { value: 'no', viewValue: 'No' },
    { value: 'quizas', viewValue: 'Quizás' },
  ];

  googleMaps: any = [
    { value: 'si', viewValue: 'Si' },
    { value: 'no', viewValue: 'No' },
  ];

  experiencias: any = [
    { value: 'con experiencia', viewValue: 'Si' },
    { value: 'sin experiencia', viewValue: 'No' },
  ];
  habilidades: any = [
    { value: 'Cocina experto', viewValue: 'Si, he sido chef o ayudante' },
    { value: 'Cocina basica', viewValue: 'Si, lo básico' },
    { value: 'No cocina', viewValue: 'No cocino' },
  ];
  tiempos: any = [
    { value: 'Menos de 6 meses', viewValue: 'Menos de 6 meses' },
    { value: 'Entre 6 y 12 meses', viewValue: 'Entre 6 y 12 meses' },
    { value: 'Entre 12 y 36 meses', viewValue: 'Entre 12 y 36 meses' },
    { value: 'Más de 36 meses', viewValue: 'Más de 36 meses' },
  ];

  situacion: any = [
    {
      value: 'Responde',
      viewValue: 'Si me gritan, o critican suelo responder a quien me ataca',
    },
    {
      value: 'Ignora',
      viewValue: 'Si me gritan, o critican suelo ingnorar a quien me ataca',
    },
  ];

  cotizas: any = [
    { value: 'si', viewValue: 'Si' },
    { value: 'no', viewValue: 'No' },
  ];
  temprano: any = [
    { value: 'si', viewValue: 'Si' },
    { value: 'no', viewValue: 'No' },
    { value: 'muy temprano', viewValue: 'Es muy temprano para mi' },
  ];
  contratoDePrestaciones: any = [
    { value: 'si', viewValue: 'Si' },
    { value: 'no', viewValue: 'No' },
    { value: 'quizas', viewValue: 'Quizás' },
  ];
  especialidadInput: String;
  createFormGroup() {
    return new FormGroup({
      //Datos principales
      especialidad: new FormControl('', [Validators.required,]),
      nombre: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(this.namePattern),
      ]),
      apellido: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(this.namePattern),
      ]),
      tipodocumento: new FormControl('', [Validators.required,]),
      permiso: new FormControl('si'),
      documento: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern(this.numberPattern),
      ]),
      nacionalidad: new FormControl('', [Validators.required,]),
      telefono: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(10),
        Validators.pattern(this.numberPattern),
      ]),
      edad: new FormControl('', [ Validators.required, ]),
      direccion: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      ciudad: new FormControl('', [Validators.required,]),
      zona: new FormControl('', [Validators.required,]),
      barrio: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern(this.emailPattern),
      ]),

      //Preguntas adicionales
      comoTeEnteraste: new FormControl(null),
      utilizaGoogleMaps: new FormControl(null),
      nivelEducativo: new FormControl(null),
      smartphone: new FormControl(null),
      numeroDeHijos: new FormControl(null),
      edadDeHijos: new FormControl(null),
      ingresaTemprano: new FormControl(null),
      disponibilidadDeTiempo: new FormControl(null),
      tieneMoto: new FormControl(null),
      tipoPersona: new FormControl(null),
      dispuestoEnMovilizarce: new FormControl(null),
      experienciaEnAseo1: new FormControl(null),
      //habilidades
      habilidades: new FormControl(''),
      aseo: new FormControl(false),
      planchado: new FormControl(false),
      cocina: new FormControl(false),
      ninos: new FormControl(false),
      adultos: new FormControl(false),
      residuos: new FormControl(false),
      desinfeccion: new FormControl(false),
      cafeteria: new FormControl(false),
      atencion: new FormControl(false),
      vidrios: new FormControl(false),
      electricista: new FormControl(false),
      ingles: new FormControl(false),
      licencia: new FormControl(false),
      camara: new FormControl(false),
      vendedor: new FormControl(false),
      plomero: new FormControl(false),
      pintor: new FormControl(false),
      jardinero: new FormControl(false),

      fechaDeNacimiento: new FormControl(''),
      fechaCreacion: new FormControl(''),
      nota: new FormControl(''),
      estado: new FormControl('Solicitud recibida'),
      idLocation: new FormControl(''),
      imgCedula2: new FormControl(''),
      certificadoEstudio: new FormControl(''),
      imgCedula: new FormControl(''),
      hojaVida: new FormControl(''),
      certificado: new FormControl(''),
      recibo: new FormControl(''),
      foto: new FormControl(''),
    });
  }

  contactForm: FormGroup;
  constructor(
    private router: Router,
    private dbData: DataDbService,
    private storage: AngularFireStorage,
    private afs: AngularFirestore
  ) {
    this.contactForm = this.createFormGroup();
  }
  //traer usuarios

  public getCorreos() {
    return this.afs.collection('contactos/').get();
  }

  ngOnInit(): void { }

  onUploadHojaVida(e) {
    this.general_loader = true;
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value}/HojaDeVida`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task.snapshotChanges().pipe(
        finalize(() => {
          this.ref.getDownloadURL().subscribe((url) => {          
            this.contactForm.get('hojaVida').setValue(url);
            this.general_loader = false;
            Swal.fire({
              title: 'Listo',
              text: 'Archivo cargado',
              icon: 'success',
            });
          });
        })
      )
      .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }else{
      e.target.files = [];
      this.general_loader = false;
      Swal.fire({
        title: 'Espera',
        text: 'Completa el antes el formulario',
        icon: 'success',
      });
    }
  }

  onUploadCedula(e) {
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value
        }/Cedula`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.contactForm.get('imgCedula').setValue(url);
              console.log(url);
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  }
  onUploadCertificadoEstudio(e) {
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value
        }/CertificadoEstudio`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.contactForm.get('certificadoEstudio').setValue(url);
              console.log(url);
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  }

  onUploadCertificados(e) {
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value
        }/Certificado`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.contactForm.get('certificado').setValue(url);
              console.log(url);
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  }

  onUploadFoto(e) {
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value
        }/Foto`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.contactForm.get('foto').setValue(url);
              console.log(url);
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  }

  onUploadRecibo(e) {
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value
        }/Recibo`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.contactForm.get('recibo').setValue(url);
              console.log(url);
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  }

  onUploadCedula2(e) {
    if (this.contactForm.get('documento').value != '') {
      const filePath = `Aspirante/${this.contactForm.get('documento').value
        }/CedulaCara2`;
      this.ref = this.storage.ref(filePath);
      this.task = this.ref.put(e.target.files[0]);
      this.task
        .snapshotChanges()
        .pipe(
          finalize(() => {
            this.ref.getDownloadURL().subscribe((url) => {
              this.contactForm.get('imgCedula2').setValue(url);
              console.log(url);
            });
          })
        )
        .subscribe();
      this.uploadProgress = this.task.percentageChanges();
      this.downloadURL = this.ref.getDownloadURL();
    }
  }


  getPuntaje() {
    let puntaje = 0;
    this.contactForm.get('fechaCreacion').setValue(Date.now());
    this.contactForm.get('nota').setValue(puntaje);
    let edad = moment().diff(this.contactForm.get('fechaDeNacimiento').value,'years');
    this.contactForm.get('edad').setValue(edad);
    // if(this.visualizarHabilidad != 'vendedor' && this.visualizarHabilidad != 'logistica' && this.visualizarHabilidad === 'reclutamiento') this.contactForm.get('hojaVida').setValue('falta');
    this.onSaveForm();
  }
  onSaveForm() {
    this.general_loader = true;
    console.log(this.contactForm);
    if (this.contactForm.valid) {
      if(this.visualizarHabilidad === 'vendedor' || this.visualizarHabilidad === 'logistica' || this.visualizarHabilidad === 'reclutamiento'){
        this.general_loader = false;
        this.dbData.saveAspiranteServ(this.contactForm.value).then(()=>this.redirigir())
      }else{
        this.general_loader = false;
        this.dbData.saveAspiranteServer(this.contactForm.value).then(()=>this.redirigir())
      }
    } else if (this.contactForm.get('nombre').invalid) {
      console.log(this.contactForm.get('nombre').errors)
      this.general_loader = false;
      Swal.fire({
        title: 'Espera',
        text: 'Parece que te has dejado un campo vacio. Recuerda que debes llenar todos los campos.',
        icon: 'warning',
      });
    } else if (this.contactForm.get('apellido').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa el campo Apellido',
        icon: 'warning',
      });
    } else if (this.contactForm.get('email').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa el campo de Correo Electronico',
        icon: 'warning',
      });
    } else if (this.contactForm.get('telefono').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa el campo de Celular',
        icon: 'warning',
      });
    } else if (this.contactForm.get('documento').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa el campo de Documento',
        icon: 'warning',
      });
    } else if (this.contactForm.get('direccion').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa por favor revisa el campo de Dirección',
        icon: 'warning',
      });
    } else if (this.contactForm.get('ciudad').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa revisa el campo de Ciudad',
        icon: 'warning',
      });
    } else if (this.contactForm.get('barrio').invalid) {
      this.general_loader = false;
      Swal.fire({
        title: 'Ha ocurrido un error',
        text: 'Por favor revisa el campo de Barrio',
        icon: 'warning',
      });
    } else if (this.contactForm.invalid){
      this.general_loader = false;
      Swal.fire({
        title: 'Espera',
        text: 'Parece que te dejaste algo sin responder, completa todas las preguntas para continuar',
        icon: 'warning',
      });
    }
  }

  redirigir(){
    fbq('track', 'register_completed');
    console.log(this.contactForm);
    Swal.fire({
      title: 'Los datos se han enviado Correctamente',
      text: 'Tus datos serán revisados',
      icon: 'success',
      confirmButtonText: 'Listo',
    }).finally(()=>{
      const url = 'https://servpremium.com/gracias-servers';
      window.location.href = url;
    })
  }

  //formulario de trabajo
  get especialidad() {
    return this.contactForm.get('especialidad');
  }
  get comoTeEnteraste() {
    return this.contactForm.get('comoTeEnteraste');
  }
  get nombre() {
    return this.contactForm.get('nombre');
  }
  get apellido() {
    return this.contactForm.get('apellido');
  }
  get email() {
    return this.contactForm.get('email');
  }
  get telefono() {
    return this.contactForm.get('telefono');
  }
  get fechaDeNacimiento() {
    return this.contactForm.get('fechaDeNacimiento');
  }
  get edad() {
    return this.contactForm.get('edad');
  }
  get tipoDeCedula() {
    return this.contactForm.get('tipoDeCedula');
  }
  get documento() {
    return this.contactForm.get('documento');
  }
  get nacionalidad() {
    return this.contactForm.get('nacionalidad');
  }
  get permiso() {
    return this.contactForm.get('permiso');
  }
  get direccion() {
    return this.contactForm.get('direccion');
  }
  get ciudadDeResidencia() {
    return this.contactForm.get('ciudadDeResidencia');
  }
  get zonaEnLaQueReside() {
    return this.contactForm.get('zonaEnLaQueReside');
  }
  get barrio() {
    return this.contactForm.get('barrio');
  }
  get obligacionesMensuales() {
    return this.contactForm.get('obligacionesMensuales');
  }
  get estadoCivil() {
    return this.contactForm.get('estadoCivil');
  }
  get viveCon() {
    return this.contactForm.get('viveCon');
  }
  get cuantosHijos() {
    return this.contactForm.get('cuantosHijos');
  }
  get edadDeHijos() {
    return this.contactForm.get('edadDeHijos');
  }
  get viviendaPropia() {
    return this.contactForm.get('viviendaPropia');
  }
  get alimentadorParaEstacion() {
    return this.contactForm.get('alimentadorParaEstacion');
  }

  get nombreAdicional() {
    return this.contactForm.get('nombreAdicional');
  }
  get parentescoAdicional() {
    return this.contactForm.get('parentescoAdicional');
  }
  get telefonoAdicional() {
    return this.contactForm.get('telefonoAdicional');
  }
  get soloLlamadas() {
    return this.contactForm.get('soloLlamadas');
  }
  get telefonoInteligente() {
    return this.contactForm.get('telefonoInteligente');
  }
  get correoPropio() {
    return this.contactForm.get('correoPropio');
  }
  get planDeDatos() {
    return this.contactForm.get('planDeDatos');
  }
  get appGoogleNequi() {
    return this.contactForm.get('appGoogleNequi');
  }
  get appWhatsFace() {
    return this.contactForm.get('appWhatsFace');
  }
  get appSoloWifi() {
    return this.contactForm.get('appSoloWifi');
  }
  get transmiSitp() {
    return this.contactForm.get('transmiSitp');
  }
  get bus() {
    return this.contactForm.get('bus');
  }
  get taxiUber() {
    return this.contactForm.get('taxiUber');
  }
  get bicicleta() {
    return this.contactForm.get('bicicleta');
  }
  get motoCarroPropio() {
    return this.contactForm.get('motoCarroPropio');
  }
  get busIntermunicipal() {
    return this.contactForm.get('busIntermunicipal');
  }
  get nivelEducativo() {
    return this.contactForm.get('nivelEducativo');
  }
  get estudiaActualmente() {
    return this.contactForm.get('estudiasActualmente');
  }
  get disponibilidadDeTiempo() {
    return this.contactForm.get('disponibilidadDeTiempo');
  }
  get plancharCamisasYDelicadas() {
    return this.contactForm.get('plancharCamisasYDelicadas');
  }
  get horarioFlexible() {
    return this.contactForm.get('horarioFlexible');
  }
  get habilidadDeCocina() {
    return this.contactForm.get('habilidadDeCocina');
  }
  get experienciaComprobanteDeEmpresas() {
    return this.contactForm.get('experienciaComprobanteDeEmpresas');
  }
  get tiempoDeExperiencia() {
    return this.contactForm.get('tiempoDeExperiencia');
  }
  get cotizaSaludPorSuCuenta() {
    return this.contactForm.get('cotizaSaludPorSuCuenta');
  }
  get trabajoTemprano() {
    return this.contactForm.get('trabajoTemprano');
  }
  get contratoPorPrestacionDeServicios() {
    return this.contactForm.get('contratoPorPrestacionDeServicios');
  }

  get nuncaHeTrabajadoComoAseador() {
    return this.contactForm.get('nuncaHeTrabajadoComoAseador');
  }
  get heTrabajadoComoAseador() {
    return this.contactForm.get('heTrabajadoComoAseador');
  }
  get soyMadreCabeza() {
    return this.contactForm.get('soyMadreCabeza');
  }
  get tengoMiPropioNegocio() {
    return this.contactForm.get('tengoMiPropioNegocio');
  }
  get tengoDeudasPorPagar() {
    return this.contactForm.get('tengoDeudasPorPagar');
  }
  get lasPersonasSeCreenMasQueYo() {
    return this.contactForm.get('lasPersonasSeCreenMasQueYo');
  }
  get meGustaPrestarUnExcelenteServicio() {
    return this.contactForm.get('meGustaPrestarUnExcelenteServicio');
  }
  get soyTranquilaCallada() {
    return this.contactForm.get('soyTranquilaCallada');
  }
  get soyAlegreHabladora() {
    return this.contactForm.get('soyAlegreHabladora');
  }
  get puedoPrestarMejorServicioAseo() {
    return this.contactForm.get('puedoPrestarMejorServicioAseo');
  }
  get siGritanRespondo() {
    return this.contactForm.get('siGritanRespondo');
  }
  get siGritanIgnoro() {
    return this.contactForm.get('siGritanIgnoro');
  }
  get masPersonasACargoEconomicamente() {
    return this.contactForm.get('masPersonasACargoEconomicamente');
  }
  get ultimoJefe() {
    return this.contactForm.get('ultimoJefe');
  }
  get ultimaEmpresa() {
    return this.contactForm.get('ultimaEmpresa');
  }
  get ultimaEmpresaTelefono() {
    return this.contactForm.get('ultimaEmpresaTelefono');
  }

  get ultimaEmpresaDuracion() {
    return this.contactForm.get('ultimaEmpresaDuracion');
  }
  get ultimaEmpresaMotivo() {
    return this.contactForm.get('ultimaEmpresaMotivo');
  }

  get ultimaEmpresaLimpiaba() {
    return this.contactForm.get('ultimaEmpresaLimpiaba');
  }
  get pagabanSalud1() {
    return this.contactForm.get('pagabanSalud1');
  }
  get ultimaEmpresaJornada() {
    return this.contactForm.get('ultimaEmpresaJornada');
  }
  get ultimaEmpresaSalario() {
    return this.contactForm.get('ultimaEmpresaSalario');
  }
  get NombreJefeReferencia2() {
    return this.contactForm.get('NombreJefeReferencia2');
  }
  get nombreEmpresaCasa2() {
    return this.contactForm.get('nombreEmpresaCasa2');
  }
  get telefono21() {
    return this.contactForm.get('telefono21');
  }

  get tiempoTrabajado2() {
    return this.contactForm.get('tiempoTrabajado2');
  }
  get razonSalida2() {
    return this.contactForm.get('razonSalida2');
  }

  get relizabasFuncionAseo2() {
    return this.contactForm.get('relizabasFuncionAseo2');
  }
  get pagabanSalud2() {
    return this.contactForm.get('pagabanSalud2');
  }
  get tipoDeJornadaLaboral2() {
    return this.contactForm.get('tipoDeJornadaLaboral2');
  }
  get salarioEmpresa2() {
    return this.contactForm.get('salarioEmpresa2');
  }
  get aseoGeneral() {
    return this.contactForm.get('esAseador');
  }
  get cocinaBasicaGourmet() {
    return this.contactForm.get('cocinaBasicaGourmet');
  }
  get trabajoElectrico() {
    return this.contactForm.get('trabajoElectrico');
  }
  get pinturaReparaciones() {
    return this.contactForm.get('pinturaReparaciones');
  }
  get trabajoAlturas() {
    return this.contactForm.get('trabajoAlturas');
  }
  get cuidadoNinos() {
    return this.contactForm.get('cuidadoNinos');
  }
  get habloIngles() {
    return this.contactForm.get('habloIngles');
  }
  get jardineria() {
    return this.contactForm.get('jardineria');
  }
  get cuidadoDeAdultosMayores() {
    return this.contactForm.get('cuidadoDeAdultosMayores');
  }
  get cuidadoMascotas() {
    return this.contactForm.get('cuidadoMascotas');
  }
  get atencionEventos() {
    return this.contactForm.get('atencionEventos');
  }
  get desinfeccionFumigacion() {
    return this.contactForm.get('desinfeccionFumigacion');
  }
  get cafeteriaMeseria() {
    return this.contactForm.get('cafeteriaMeseria');
  }
  get construccionAlbanileria() {
    return this.contactForm.get('construccionAlbanileria');
  }
  get trabajoExcel() {
    return this.contactForm.get('trabajoExcel');
  }
  get licenciaConduccion() {
    return this.contactForm.get('licenciaConduccion');
  }
  get controlDePlagas() {
    return this.contactForm.get('controlDePlagas');
  }
  get trabajoDeInterna() {
    return this.contactForm.get('trabajoDeInterna');
  }
  get experienciaEnLimpiezaDesinfeccionHospitalaria() {
    return this.contactForm.get(
      'experienciaEnLimpiezaDesinfeccionHospitalaria'
    );
  }
  get cargueDescargueMercancia() {
    return this.contactForm.get('cargueDescargueMercancia');
  }
  get limpiezaDesinfeccionAreasComunes() {
    return this.contactForm.get('limpiezaDesinfeccionAreasComunes');
  }

  get mensaje() {
    return this.contactForm.get('mensaje');
  }

  public autoGrowTextZone(e) {
    e.target.style.height = "0px";
    e.target.style.height = e.target.scrollHeight + 5 + "px";
  }

}